import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import IndividualReportsService from '../../../../services/IndividualReportsService';

interface Values {
  payout_amount: string;
  description: string;
}

interface Props {
  individual: string;
  handleExternalEvent: Function;
}

function PayoutAmountForm({ individual, handleExternalEvent }: Props) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const addPayout = (payout_amount: string, description: string) => {
    IndividualReportsService.addPayout(individual, payout_amount, description).then(() => {
        console.log('payout added');
        handleExternalEvent();
    })
}

  useEffect(() => {
    function handleFocus(event: FocusEvent) {
      if (dialogRef.current && dialogRef.current.open && !dialogRef.current.contains(event.target as Node)) {
        event.stopPropagation();
        dialogRef.current.focus();
      }
    }

    document.addEventListener('focus', handleFocus, true);
    return () => {
      document.removeEventListener('focus', handleFocus);
    }
  }, []);

  const handleOpen = () => {
    if (dialogRef.current) dialogRef.current.showModal();
  };

  const handleClose = () => {
    if (dialogRef.current) dialogRef.current.close();
  };

  const handleValidate = (values: Values) => {
    const errors: Partial<Values> = {};
    if (!values.payout_amount) {
      errors.payout_amount = 'Required';
    } else if (isNaN(Number(values.payout_amount))) {
      errors.payout_amount = 'Must be a number';
    }
    return errors;
  };

  const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    setTimeout(() => {
      console.log(`Submitted values: ` + JSON.stringify(values, null, 2));
      addPayout(values.payout_amount, values.description);
      setSubmitting(false);
      handleClose();
    }, 400);
  };

  return (
    <div>
      <div className={"add-payout-btn"} onClick={handleOpen}>
          <span>Внести выплату</span>
      </div>

      <dialog ref={dialogRef}>
        <Formik
          initialValues={{ payout_amount: "0" , description:""}}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <h1>Enter Payout Amount</h1>
              <p>
                <label htmlFor="payout_amount">Сумма к выплате</label>
                <Field type="number" step="0.01" name="payout_amount"/>
                <ErrorMessage name="payout_amount" component="div"/>
              </p>
              <p>
                <label htmlFor="description">Комментарий</label>
                <Field type="text" name="description"/>
                <ErrorMessage name="description" component="div"/>
              </p>
              <menu>
                <button type="button" onClick={handleClose}>Cancel</button>
                <button type="submit" disabled={isSubmitting}>Submit</button>
              </menu>
            </Form>
          )}
        </Formik>
      </dialog>
    </div>
  );
}

export default PayoutAmountForm;
